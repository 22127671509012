//=require ./../inc/light.js
//=require ./../inc/regular.js
//=require ./../inc/solid.js
//=require ./../inc/fontawesome.js
//=require js-cookie/src/js.cookie.js
//=require mustache/mustache.js
//=require jquery/dist/jquery.js
//=require ./../inc/bootstrap-extra.js
//=require bootstrap/dist/js/bootstrap.bundle.js
//=require ../../node_modules/bootstrap-select/dist/js/bootstrap-select.js
//=require ../../node_modules/bootstrap-autocomplete/dist/latest/bootstrap-autocomplete.js
//=require ../../node_modules/bootstrap-fileinput/js/fileinput.js
//=require ../../node_modules/js-cookie/src/js.cookie.js
//=require parsleyjs/dist/parsley.js
//=require parsleyjs/dist/i18n/de.js
//=require parsleyjs/dist/i18n/de.extra.js

$('[data-toggle="popover"]').popover();

function isUrlProtocol(url) {
    var r = new RegExp('^(?:[a-z]+:)?//', 'i');
    return r.test(url);
}

$('.js-link-protocol-fallback').click(function() {
    var url = $(this).attr('href');
    if (!isUrlProtocol(url)) {
        $(this).attr('href', 'http://' + url);
    }
});

$(".js-set-back-url").click(function() {
    Cookies.set('backUrl', window.location.href);
});